<template>
	<div class="pui-form">
		<edimensaje-modals
			:modelName="modelName"
		></edimensaje-modals>
		<pui-form-header
			v-if="modelLoaded"
			:showHeader="!isCreatingElement">
			<edimensaje-form-header
				:modelPk="modelPk"
			></edimensaje-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('edimensaje.tabs.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#auditoria'" v-if="!isCreatingElement && !isModalDialog">{{ $t('edimensaje.tabs.edimensajeauditoria') }}</v-tab>  <!-- change detailmodelname_1 -->
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="'maintab'">
					<v-row dense v-if="!isCreatingElement">
						<!-- IDMENSAJE -->
						<v-col cols="4">
							<pui-number-field
								:id="`idmensaje-edimensaje`"
								v-model="model.idmensaje"
								:label="$t('edimensaje.idmensaje')"
								:disabled="formDisabled"
								required
								toplabel
								integer
								min="0"
								max="99999"
							></pui-number-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- CODPUE -->
						<v-col cols="6" md="4">
							<pui-text-field v-if="false"
								:id="`codpue-edimensaje`"
								v-model="model.codpue"
								:label="$t('edimensaje.codpue')"
								:disabled="formDisabled"
								toplabel
								maxlength="5"
							></pui-text-field>
							<pui-text-field
								:id="`codpue-edimensaje`"
								v-model="model.codpuedesc"
								:label="$t('edimensaje.codpue')"
								:disabled="formDisabled"
								required
								toplabel
							></pui-text-field>
						</v-col>
						<!-- IDESTADO -->
						<v-col cols="6" md="4">
							<pui-number-field v-if="false"
								:id="`idestado-edimensaje`"
								v-model="model.idestadodesc"
								:label="$t('edimensaje.idestado')"
								:disabled="formDisabled"
								required
								toplabel
								integer
								min="0"
								max="99999"
							></pui-number-field>
							<pui-text-field
								:id="`idestado-edimensaje`"
								v-model="model.idestadodesc"
								:label="$t('edimensaje.idestado')"
								:disabled="formDisabled"
								required
								toplabel
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- SENTIDO -->
						<v-col cols="12" sm="6" md="1">
							<pui-text-field
								:id="`sentido-edimensaje`"
								v-model="model.sentidodesc"
								:label="$t('edimensaje.sentido')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="1"
							></pui-text-field>
						</v-col>
						<!-- IDTIPOMENSAJE -->
						<v-col cols="12" sm="6" md="2">
							<pui-number-field v-if="false"
								:id="`idtipomensaje-edimensaje`"
								v-model="model.idtipomensaje"
								:label="$t('edimensaje.idtipomensaje')"
								:disabled="formDisabled"
								required
								toplabel
								integer
								min="0"
								max="99999"
							></pui-number-field>
							<pui-text-field
								:id="`idtipomensaje-edimensaje`"
								v-model="model.idtipomensajedesc"
								:label="$t('edimensaje.idtipomensaje')"
								:disabled="formDisabled"
								required
								toplabel
							></pui-text-field>
						</v-col>
						<!-- CLASEMENSAJE -->
						<v-col cols="12" sm="4" md="3" xl="1">
							<pui-text-field
								:id="`clasemensaje-edimensaje`"
								v-model="model.clasemensaje"
								:label="$t('edimensaje.clasemensaje')"
								:disabled="formDisabled"
								toplabel
								maxlength="6"
							></pui-text-field>
						</v-col>
						<!-- IDFUNCION -->
						<v-col cols="12" sm="8" md="3">
							<pui-number-field v-if="false"
								:id="`idfuncion-edimensaje`"
								v-model="model.idfuncion"
								:label="$t('edimensaje.idfuncion')"
								:disabled="formDisabled"
								toplabel
								integer
								min="0"
								max="99999"
							></pui-number-field>
							<pui-text-field
								:id="`idfuncion-edimensaje`"
								v-model="model.idfunciondesc"
								:label="$t('edimensaje.idfuncion')"
								:disabled="formDisabled"
								required
								toplabel
							></pui-text-field>
						</v-col>
						<!-- CODTIPODOCUMENTO -->
						<v-col cols="4" sm="3" md="2">
							<pui-text-field
								:id="`codtipodocumento-edimensaje`"
								v-model="model.codtipodocumento"
								:label="$t('edimensaje.codtipodocumento')"
								:disabled="formDisabled"
								toplabel
								maxlength="3"
							></pui-text-field>
						</v-col>
						<!-- INDCABECERA -->
						<v-col cols="4" md="1" class="pt-7">
							<pui-checkbox
								:label="$t('edimensaje.indcabecera')"
								v-model="model.indcabecera"
								true-value="1"
								false-value="0"
								disabled
							></pui-checkbox>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- BUZONEMISOR -->
						<v-col cols="6" md="2">
							<pui-text-field
								:id="`buzonemisor-edimensaje`"
								v-model="model.buzonemisor"
								:label="$t('edimensaje.buzonemisor')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
						<!-- BUZONRECEPTOR -->
						<v-col cols="6" md="2">
							<pui-text-field
								:id="`buzonreceptor-edimensaje`"
								v-model="model.buzonreceptor"
								:label="$t('edimensaje.buzonreceptor')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
						<!-- NUMREFTRANSMISOR -->
						<v-col cols="6" md="4" xl="3">
							<pui-text-field
								:id="`numreftransmisor-edimensaje`"
								v-model="model.numreftransmisor"
								:label="$t('edimensaje.numreftransmisor')"
								:disabled="formDisabled"
								toplabel
								maxlength="14"
							></pui-text-field>
						</v-col>
						<!-- NUMREFEMISOR -->
						<v-col cols="6" md="4" xl="3">
							<pui-text-field
								:id="`numrefemisor-edimensaje`"
								v-model="model.numrefemisor"
								:label="$t('edimensaje.numrefemisor')"
								:disabled="formDisabled"
								toplabel
								maxlength="35"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- FECREGISTRO -->
						<v-col cols="4" lg="3" xl="2">
							<pui-date-field
								:id="`fecregistro-edimensaje`"
								v-model="model.fecregistro"
								:label="$t('edimensaje.fecregistro')"
								:disabled="formDisabled"
								required
								toplabel
								time
							></pui-date-field>
						</v-col>
						<!-- FECMENSAJE -->
						<v-col cols="4" lg="3" xl="2">
							<pui-date-field
								:id="`fecmensaje-edimensaje`"
								v-model="model.fecmensaje"
								:label="$t('edimensaje.fecmensaje')"
								:disabled="formDisabled"
								toplabel
								time
							></pui-date-field>
						</v-col>
						<!-- FECREFERENCIA -->
						<v-col cols="4" lg="3" xl="2">
							<pui-date-field
								:id="`fecreferencia-edimensaje`"
								v-model="model.fecreferencia"
								:label="$t('edimensaje.fecreferencia')"
								:disabled="formDisabled"
								toplabel
								time
							></pui-date-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- NUMREFMENSAJE -->
						<v-col cols="4">
							<pui-text-field
								:id="`numrefmensaje-edimensaje`"
								v-model="model.numrefmensaje"
								:label="$t('edimensaje.numrefmensaje')"
								:disabled="formDisabled"
								toplabel
								maxlength="35"
							></pui-text-field>
						</v-col>
						<!-- IDMENSAJEORIGEN -->
						<v-col cols="4" xl="2">
							<pui-number-field
								:id="`idmensajeorigen-edimensaje`"
								v-model="model.idmensajeorigen"
								:label="$t('edimensaje.idmensajeorigen')"
								:disabled="formDisabled"
								toplabel
								integer
								min="0"
								max="99999"
							></pui-number-field>
						</v-col>
						<!-- NUMREFORIGEN -->
						<v-col cols="4">
							<pui-text-field
								:id="`numreforigen-edimensaje`"
								v-model="model.numreforigen"
								:label="$t('edimensaje.numreforigen')"
								:disabled="formDisabled"
								toplabel
								maxlength="35"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- NOMFICHERO -->
						<v-col cols="12" sm="7" md="5">
							<pui-text-field
								:id="`nomfichero-edimensaje`"
								v-model="model.nomfichero"
								:label="$t('edimensaje.nomfichero')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
						<!-- IDDOCAGENTE -->
						<v-col cols="6" sm="5" md="2">
							<pui-text-field
								:id="`iddocagente-edimensaje`"
								v-model="model.iddocagente"
								:label="$t('edimensaje.iddocagente')"
								:disabled="formDisabled"
								toplabel
								maxlength="17"
							></pui-text-field>
						</v-col>
						<!-- IDAGENTE -->
						<v-col cols="12" md="5" xl="3">
							<pui-number-field v-if="false"
								:id="`idagente-edimensaje`"
								v-model="model.idagente"
								:label="$t('edimensaje.idagente')"
								:disabled="formDisabled"
								toplabel
								integer
								min="0"
								max="99999"
							></pui-number-field>
							<pui-text-field
								:id="`idagente-edimensaje`"
								v-model="model.idagentedesc"
								:label="$t('edimensaje.idagente')"
								:disabled="formDisabled"
								required
								toplabel
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- NOMBUQUE -->
						<v-col cols="4">
							<pui-text-field
								:id="`nombuque-edimensaje`"
								v-model="model.nombuque"
								:label="$t('edimensaje.nombuque')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
						<!-- ESCALA -->
						<v-col cols="4">
							<pui-text-field
								:id="`escala-edimensaje`"
								v-model="model.escala"
								:label="$t('edimensaje.escala')"
								:disabled="formDisabled"
								toplabel
								maxlength="14"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense v-if="false">
						<!-- NUMDECLARACION -->
						<v-col cols="4">
							<pui-text-field
								:id="`numdeclaracion-edimensaje`"
								v-model="model.numdeclaracion"
								:label="$t('edimensaje.numdeclaracion')"
								:disabled="formDisabled"
								toplabel
								maxlength="35"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- IDRESOLUCION -->
						<v-col cols="12" xl="10">
							<pui-number-field v-if="false"
								:id="`idresolucion-edimensaje`"
								v-model="model.idresolucion"
								:label="$t('edimensaje.idresolucion')"
								:disabled="formDisabled"
								toplabel
								integer
								min="0"
								max="99999"
							></pui-number-field>
							<pui-text-field
								:id="`idresolucion-edimensaje`"
								v-model="model.idresoluciondesc"
								:label="$t('edimensaje.idresolucion')"
								:disabled="formDisabled"
								toplabel
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" xl="10">
							<!-- DESERROR -->
							<pui-text-area
								:id="`deserror-edimensaje`"
								v-model="model.deserror"
								:label="$t('edimensaje.deserror')"
								:disabled="formDisabled"
								maxlength="512"
								toplabel
								rows="3"
							></pui-text-area>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<hr>
							<br>
							<label style="font-weight: bold;color:#166bf2">{{$t('edimensaje.deserrortitle')}}</label>
							<pui-master-detail
								componentName="edimensajeerror-grid"
								:parentModelName="modelName"
								:parentPk="pk"
								:parentPkChildFk="{ idmensaje: 'idmensaje' }"
								:modalDialog="isModalDialog"
								:formDisabled="true"
								:parentModel="model"
							></pui-master-detail>
						</v-col>
					</v-row>
				<!-- MINI AUDIT -->
				<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-tab-item>
				<v-tab-item :key="1" :value="'auditoria'" v-if="!isCreatingElement && !isModalDialog"> <!-- change detailmodelname_1 -->
					<pui-master-detail
						componentName="edimensajeauditoria-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idmensaje: 'idmensaje' }"
						:modalDialog="isModalDialog"
						:formDisabled="true"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading
			v-else
		></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import edimensajeActions from './EdimensajeActions';
import edimensajeModals from './EdimensajeModals.vue';

export default {
	name: 'edimensaje-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'edimensaje-modals': edimensajeModals
	},
	data() {
		return {
			modelName: 'edimensaje'
			, tabmodel: 'maintab'
			, actions: edimensajeActions.formActions
		};
	},
	methods: {
		afterGetData() {
			if(this.model.sentido!=null) {
				if(this.model.sentido=='E') this.model.sentidodesc=this.$t('edimensaje.sentidoE');
				else this.model.sentidodesc=this.$t('edimensaje.sentidoS');
			}
		}
	},
	computed: {
	},
	created() {
	}
}
</script>
